import React from "react";
import * as styles from "./ApplicationStartSuccessPage.module.scss";
import { Button, Link, Typography } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import { PinkOvals2 } from "@assets/Background";
import {} from "@components";
import { LINKS } from "@components/Link/Link";

type ApplicationStartPageProps = {
	image: "academia_start" | "institution_start" | "application_confirmation";
	header: string;
	text: string;
	includePrivacyPolicy?: boolean;
	buttonText: string;
	minutes?: number;
	onClick: () => void;
};

const ApplicationStartSuccessPage: React.FunctionComponent<
	ApplicationStartPageProps
> = ({
	image,
	header,
	text,
	includePrivacyPolicy = false,
	buttonText,
	minutes,
	onClick,
}: ApplicationStartPageProps) => {
	return (
		<div className={styles.page}>
			<div className={styles.content}>
				<StaticImage
					loading="eager"
					className={styles.logo}
					src="../../assets/images/logos/with-black-title.png"
					alt="Genemod logo"
					width={200}
				/>
				{image === "academia_start" && (
					<StaticImage
						loading="eager"
						className={styles.image}
						src={`../../assets/images/application/academia_start.png`}
						alt="Genemod logo"
						width={466}
						height={264}
					/>
				)}
				{image === "application_confirmation" && (
					<StaticImage
						loading="eager"
						className={styles.image}
						src={`../../assets/images/application/application_confirmation.png`}
						alt="Genemod logo"
						width={300}
						height={264}
					/>
				)}
				{image === "institution_start" && (
					<StaticImage
						loading="eager"
						className={styles.image}
						src={`../../assets/images/application/institution_start.png`}
						alt="Genemod logo"
						width={516}
						height={264}
					/>
				)}
				<Typography
					className={styles.header}
					variant="HEADER1"
					color="text-dark"
				>
					{header}
				</Typography>
				<Typography
					className={styles.text}
					variant="BODY"
					color="text-dark"
				>
					{text}
					{includePrivacyPolicy && (
						<>
							{" "}
							<Link
								className={styles.policy}
								target="_blank"
								to={LINKS.PRIVACY_POLICY.to}
							>
								Privacy Policy
							</Link>
							.
						</>
					)}
				</Typography>
				<Button
					className={styles.startButton}
					newLayout
					onClick={onClick}
					type="primary"
				>
					{buttonText}
				</Button>
				{minutes && (
					<div className={styles.minutes}>
						<StaticImage
							loading="eager"
							className={styles.clockIcon}
							src={`../../assets/icons/clock-icon.png`}
							alt="Clock icon"
							width={18}
							height={18}
						/>
						<Typography variant="SUBHEADERDESC" color="text-dark">
							Takes ~{minutes}min
						</Typography>
					</div>
				)}
				<div className={styles.pinkOvals}>
					<PinkOvals2 />
				</div>
			</div>
		</div>
	);
};

export default ApplicationStartSuccessPage;
