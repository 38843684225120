// extracted by mini-css-extract-plugin
export var clockIcon = "ApplicationStartSuccessPage-module--clockIcon--5pb+c";
export var content = "ApplicationStartSuccessPage-module--content--SgKFI";
export var header = "ApplicationStartSuccessPage-module--header--vUhLK";
export var image = "ApplicationStartSuccessPage-module--image--pWjmY";
export var limitWidthOnLargeScreens = "ApplicationStartSuccessPage-module--limitWidthOnLargeScreens--C7fWB";
export var logo = "ApplicationStartSuccessPage-module--logo--KUihR";
export var minutes = "ApplicationStartSuccessPage-module--minutes--1cCKI";
export var page = "ApplicationStartSuccessPage-module--page--U8Vnt";
export var pinkOvals = "ApplicationStartSuccessPage-module--pinkOvals---NUZE";
export var policy = "ApplicationStartSuccessPage-module--policy--g26PA";
export var startButton = "ApplicationStartSuccessPage-module--startButton--Iga2D";
export var text = "ApplicationStartSuccessPage-module--text--lV+Ym";