import React from "react";
import ApplicationStartSuccessPage from "@components/ApplicationStartSuccessPage/ApplicationStartSuccessPage";
import { navigate } from "gatsby";

const InstitutionConfirmation: React.FunctionComponent = () => {
	return (
		<ApplicationStartSuccessPage
			image="application_confirmation"
			header="Thank you!"
			text="Your application to Genemod’s Institution Program has been received. Our team will review your application carefully and contact you soon!"
			buttonText="Back to home page"
			onClick={() => navigate("/")}
		/>
	);
};

export default InstitutionConfirmation;
